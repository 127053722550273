@import '../../index.scss';

.home {
    margin: 0px;
}

.content-container {
    background-color: $main-color;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0;
    height: $top-bar-height;
    width: 100%;
    z-index: 20;
    @include phone {
        height: $top-bar-height-mobile;
    }
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 20px;
    @include tablet {
        width: 100%;
        margin-top: 15px;
        display: flex;
    }
    @include tablet-half {
        margin-top: 15px;
    }
    @include phone {
    }
}

.text-container {
    display: inline-block;
    @include tablet-half {
        margin-left: 0px;
    }
}

.title {
    margin-top: 20px;
    margin-bottom: 0px;
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 200;
    font-size: 50px;
    line-height: 56px;
    text-align: center;
    color: $white;
    &:hover {
        cursor: pointer;
    }
    @include tablet {
        margin-top: 20px;
        font-size: 38px;
        white-space: nowrap;
        line-height: 45px;
    }
    @include tablet-half {
        font-size: 35px;
        margin-top: 30px;
    }
    @include phone {
        margin-top: 10px;
        font-size: 24px;
    }
}

.spaced-name {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 10px;
    padding: 0 20px 0 20px;
}

.sub-title {
    margin-top: 0px;
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 100;
    font-size: 24px;
    line-height: 16px;
    text-align: center;
    color: $white;
    @include tablet {
        margin-top: 5px;
    }
    @include tablet-half {
        position: absolute;
        font-size: 24px;
        margin-top: -10px;
        margin-left: 30px;
    }
    @include phone {
        margin-top: -13px;
        font-size: 18px;
        margin-left: 25px;
    }
}

.nav-options-button {
    background: none;
    border: none;
    font-family: $secondary-font;
    font-weight: 300;
    font-size: 24px;
    color: $white;
    margin-left: 0px;
    padding: 0px;
    &:hover {
        cursor: pointer;
        color: $tertiaty-color;
    }
    @include tablet {
        margin-left: 0px;
    }

    @include phone {
        font-size: 18px;
    }
}
