@import './styles/mediaqueries';
@import './styles/colors';

//Variables
$top-bar-height: 180px;
$top-bar-height-mobile: 120px;
$main-font: 'Quicksand', serif;
$secondary-font: 'Playfair Display', serif;

body {
    margin: 0;
    font-weight: 400;
    font-family: 'Helvetica', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $main-color;
    background-color: $main-color;
}

.image-item {
    padding: 0px 10px 0px 0px;
}

body::-webkit-scrollbar {
    width: 0px;
}

body::-webkit-scrollbar-track {
    background-color: none;
}

body::-webkit-scrollbar-thumb {
    background-color: none;
    border-radius: 10px;
}
