@import '../../index.scss';

.content-container {
    display: flex;
    width: 100%;
    padding: 30px 0px 30px 0px;
    background-color: $secondary-color;
    z-index: 10;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-direction: column;
    @include tablet {
        display: block;
    }
}

.top-angle {
    display: flex;
    position: absolute;
    background-color: $secondary-color;
    height: 1100px;
    width: 100%;
    margin-top: -130px;
    transform-origin: left bottom;
    transform: skewY(2deg);
    z-index: 0;
    @include tablet {
        height: 1800px;
    }
    @include tablet-half {
        height: 1600px;
    }
    @include phone {
        margin-top: -15px;
        height: 1250px;
    }
}

.text-container {
    position: relative;
    max-width: 700px;
    z-index: 10;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    @include tablet {
    }
    @include tablet-half {
        width: 80%;
    }
}
.image-container {
    padding: 80px 80px 20px 80px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    z-index: 10;
    @include tablet {
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}

.button-section {
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    @include tablet {
    }
    @include phone {
        transform: scale(0.7);
    }
}

.button-container {
    z-index: 10;
    height: 84.5px;
    width: 342px;
    margin-top: 48px;

    background-image: url(../../images/collaborate-button.svg);
    &:hover {
        .button-text {
            color: $main-color;
        }
        cursor: pointer;
        background-image: url(../../images/collaborate-button-hover.svg);
    }
}

.description {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    @include tablet-half {
        font-size: 16px;
    }
    @include phone {
        font-size: 14px;
    }
}

.header {
    font-family: $main-font;
    font-weight: 200;
    font-size: 96px;
    margin-bottom: 50px;
    margin-top: 20px;
    @include tablet-half {
        font-size: 64px;
    }
    @include phone {
        font-size: 36px;
    }
}

.button-text {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    color: $white;
    font-family: $main-font;
    font-size: 24px;
    font-weight: 400;
    text-decoration: none;
    &:hover {
        color: $main-color;
    }
}

.picture-vertical {
    width: 30%;
    object-fit: contain;
    @include tablet {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;
        width: 530px;
        z-index: 100;
    }
    @include tablet-half {
        width: 350px;
    }
    @include phone {
        width: 100%;
    }
}

.image {
    width: 100vw;
    position: absolute;
    object-fit: cover;
    left: 0px;
    opacity: 0.9;
    top: 0px;
    height: 1200px;
    z-index: -1;
    @include tablet {
        top: $top-bar-height-mobile;
        height: 800px;
    }
    @include tablet-half {
        top: $top-bar-height-mobile;
        height: 500px;
    }
    @include phone {
        top: $top-bar-height-mobile;
        height: 300px;
    }
}
