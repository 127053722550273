@import '../../index.scss';

.content-container {
    display: flex;
    margin-top: $top-bar-height;
    padding: 70px 0px 250px 0px;
    width: 100%;
    height: 700px;
    justify-content: center;
    @include tablet {
        height: 400px;
    }
    @include tablet-half {
        height: 80px;
    }
    @include phone {
        height: 180px;
        padding: 0px 0px 50px 0px;
    }
}

.button-container {
    text-align: left;
    margin-left: 700px;
    margin-top: 250px;
    margin-right: 70px;
    @include tablet {
        margin-top: 50px;
        margin-left: 400px;
    }
    @include tablet-half {
        margin-top: 0px;
        margin-left: 200px;
    }
    @include phone {
        margin-left: 250px;
        margin-top: -80px;
        transform: scale(0.7);
    }
}

.text {
    color: $white;
    font-family: $secondary-font;
    font-weight: 200;
    font-size: 64px;
    margin-bottom: 40px;
    @include tablet-half {
        font-size: 40px;
    }
}

.email-button-container {
    margin-top: 65px;
    @include tablet-half {
        margin-top: 50px;
    }
}

.button {
    text-decoration: none;
    color: black;
    font-family: $secondary-font;
    background: $white;
    font-weight: 400;
    font-size: 24px;
    border-radius: 0;
    border-width: 2px;
    border-color: $white;
    border-style: solid;
    padding: 50px 70px 50px 70px;
    height: 100px;
    width: 200px;
    transition: background-color 0.3s, border-color 0.3s;

    &:hover {
        background-color: $tertiaty-color;
        border-color: $tertiaty-color;
        cursor: pointer;
    }
    @include tablet-half {
        padding: 40px 50px 40px 50px;
    }
}

.svg {
    filter: invert(100%) sepia(6%) saturate(0%) hue-rotate(3deg)
        brightness(100%) contrast(100%);
    height: 50px;
    transition: filter 0.3s, color 0.3s;
    padding-right: 10px;

    &:hover {
        filter: invert(96%) sepia(6%) saturate(1286%) hue-rotate(3deg)
            brightness(93%) contrast(102%);
        cursor: pointer;
    }
    @include tablet-half {
        height: 35px;
    }
}

.transparent-button {
    background: transparent;
    border: none;
    padding: 0px;
    margin: 0px;
}
