@import '../../index.scss';

.scroll-button {
    position: fixed;
    padding: 0px 0px 10px 0px;
    right: 35px;
    bottom: 35px;
    height: 55px;
    width: 55px;
    font-size: 1.8rem;
    cursor: pointer;
    color: $white;
    background-color: $secondary-color;
    color: $main-color;
    border-radius: 30px;
    border-width: 1px;
    border-color: $main-color;
    z-index: 100;
}

.button-constraints {
    max-width: 500px;
}
