@import '../../index.scss';

.content-container {
    justify-content: center;
    width: 1200px;
    background-color: $main-color;
    margin-right: auto;
    margin-left: auto;
    @include tablet {
        width: 780px;
    }
    @include tablet-half {
        width: 80%;
    }
}

.section-title {
    margin-top: 100px;
    color: $white;
    font-family: $main-font;
    font-size: 96px;
    font-weight: 200;
    text-align: left;
    @include phone {
        font-size: 36px;
        margin-top: 50px;
    }
}

.section-sub-title {
    margin-top: 70px;
    color: $white;
    font-family: $main-font;
    font-size: 50px;
    font-weight: 200;
    text-align: left;
    @include phone {
        font-size: 24px;
        margin-top: 30px;
    }
}

.video-container {
    display: inline-block;
    text-align: left;
}

.video-content {
    text-align: left;
    vertical-align: top;
    margin: 20px;
    width: 560px;
    display: inline-block;
    @include tablet {
        width: 780px;
        margin: 0px 0px 40px 0px;
    }
    @include tablet-half {
        width: 100%;
    }
}

.video-box {
    width: 100%;
}

.video-title {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 3px;
    font-family: $main-font;
    color: $white;
}

.video-sub-title {
    font-size: 18px;
    font-weight: 200;
    margin-top: 0px;
    margin-bottom: 4px;
    font-family: $secondary-font;
    color: $white;
}

.reel-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include tablet-half {
        flex-direction: column;
    }
}

.reel {
    justify-content: center;
    width: 1200px;
    @include tablet {
        width: 780px;
    }
    @include tablet-half {
        width: 100%;
    }
}

.reel-image {
    margin-right: 40px;
    height: 100%;
    width: 420px;
    object-fit: cover;
    @include tablet {
        width: 300px;
    }
    @include tablet-half {
        height: 250px;
        width: 100%;
        margin-right: 0px;
        margin-bottom: 30px;
    }
}

.invisible {
    display: none;
}
