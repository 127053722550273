@mixin regular-desktop-screen {
    @media (min-width: 1250px) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: 1250px) {
        @content;
    }
}

@mixin tablet-half {
    @media (max-width: 790px) {
        @content;
    }
}

@mixin phone {
    @media (max-width: 500px) {
        @content;
    }
}
