@import '../../index.scss';

.content-container {
    padding: 70px 0px 300px 0px;
    background-color: $main-color;
    z-index: 10;
    @include phone {
        padding: 70px 0px 150px 0px;
    }
}
