@import '../../index.scss';

.content-container {
    background-color: $main-color;
    display: flex;
    justify-content: space-between;
    height: 100px;
    width: 100%;
    z-index: 20;
    @include phone {
        justify-content: left;
    }
}

.button-container {
    display: inline-block;
    margin-top: 40px;
    margin-left: 70px;
    @include tablet-half {
        margin-left: 20px;
    }
}

.text-container {
    display: inline-block;
    margin-top: 20px;
    margin-right: 110px;
}

.sub-title {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 100;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    color: $white;
}

.nav-options-button {
    background: none;
    border: none;
    font-family: $secondary-font;
    font-size: 18px;
    font-weight: 300;
    color: $white;
    margin-left: 50px;
    &:hover {
        cursor: pointer;
        color: $tertiaty-color;
    }
    @include tablet-half {
        font-size: 14px;
        margin-left: 10px;
    }
    @include phone {
        display: none;
    }
}
