@import '../../index.scss';

.content-container {
    display: flex;
    justify-content: center;
    width: auto;
    padding: 70px 0px 170px 0px;
    background-color: $secondary-color;
    z-index: 10;
    margin-right: auto;
    margin-left: auto;
    @include phone {
        height: 250px;
        padding: 0px 0px 50px 0px;
        transform: scale(0.7);
    }
}

.content-background {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: $secondary-color;
    z-index: 10;
}

.top-angle {
    display: flex;
    position: absolute;
    background-color: $secondary-color;
    height: 350px;
    width: 100%;
    margin-top: -130px;
    transform-origin: left bottom;
    transform: skewY(2deg);
    z-index: 0;
    @include phone {
        margin-top: -50px;
        height: 250px;
    }
}

.text-container {
    margin-left: 70px;
    margin-right: auto;
    max-width: 700px;
    text-align: left;
    padding-right: 85px;
    z-index: 10;
    @include tablet-half {
        padding-right: 35px;
    }
    @include phone {
        margin-left: 0px;
    }
}
.image-container {
    position: relative;
    float: left;
    width: 500px;
    z-index: 10;
}

.description {
    margin-top: 0px;
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 18px;
}

.header {
    font-family: $main-font;
    font-weight: 100;
    font-size: 74px;
    margin-bottom: 10px;
    margin-top: 20px;
    @include tablet-half {
        font-size: 42px;
    }
}

.star-picture {
    position: absolute;
    right: 180px;
}

.star-picture-two {
    position: absolute;
    right: 0px;
    bottom: 300px;
    transform: scale(0.55);
}

.star-picture-three {
    position: absolute;
    right: 20px;
    bottom: 10px;
    transform: scale(0.75);
}

.link-header {
    text-decoration: none;
    margin-top: 80px;
    margin-bottom: 0px;
    color: $main-color;
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 24px;
}

.link-text {
    background-color: transparent;
    margin-top: 0px;
    color: $main-color;
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 24px;
}

.button-container {
    text-align: left;
    margin-left: 70px;
    margin-top: 0px;
    margin-right: 70px;
    @include tablet {
        margin-left: 0px;
    }
    @include tablet-half {
        margin-top: 50px;
    }
    @include phone {
        margin-top: 30px;
        margin-right: 0px;
    }
}

.text {
    color: $white;
    font-family: $secondary-font;
    font-weight: 200;
    font-size: 64px;
    margin-bottom: 40px;
}

.email-button-container {
    margin-top: 65px;
    @include tablet-half {
        margin-top: 50px;
    }
}

.button {
    text-decoration: none;
    color: $white;
    font-family: $secondary-font;
    background: black;
    font-weight: 400;
    font-size: 24px;
    border-radius: 0;
    border-width: 2px;
    border-color: $white;
    border-style: black;
    padding: 50px 70px 50px 70px;
    height: 100px;
    width: 200px;
    transition: background-color 0.3s, border-color 0.3s;

    &:hover {
        background-color: $tertiaty-color;
        border-color: $tertiaty-color;
        cursor: pointer;
    }
    @include tablet-half {
        padding: 40px 50px 40px 50px;
    }
}

.svg {
    filter: invert(0%) sepia(6%) saturate(0%) hue-rotate(3deg) brightness(100%)
        contrast(100%);
    height: 50px;
    transition: filter 0.3s, color 0.3s;
    padding-right: 10px;

    &:hover {
        filter: invert(96%) sepia(6%) saturate(1286%) hue-rotate(3deg)
            brightness(93%) contrast(102%);
        cursor: pointer;
    }
    @include tablet-half {
        height: 35px;
    }
}

.transparent-button {
    background: transparent;
    border: none;
    padding: 0px;
    margin: 0px;
}
